import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const HeroLabel = ({children, className}) => (
  <div className={classnames('domain-hero__label', className)}>
    <i>{children}</i>
  </div>
);

HeroLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

export default HeroLabel;
