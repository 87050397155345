import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import classnames from 'classnames';
import BackgroundImage from 'gatsby-background-image';

import HeroCaption from './hero-caption';
import HeroLabel from './hero-label';

const DomainHero = ({fileName, className, caption, label}) => {
  const classes = classnames('domain-hero', className);
  const data = useStaticQuery(
    graphql`
      query {
        placeholderImage: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 100, maxWidth: 2100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `
  );
  const image = data.placeholderImage.edges.find(({node}) => node.relativePath === fileName).node;
  if (!image) {
    return null;
  }

  return (
    <div>
      <BackgroundImage Tag="section" className={classes} fluid={image.childImageSharp.fluid}>
        <div className="d-none d-lg-flex flex-column w-100">
          <HeroCaption>{caption}</HeroCaption>
          <HeroLabel>{label}</HeroLabel>
        </div>
      </BackgroundImage>
      <HeroLabel className="d-block d-lg-none">{label}</HeroLabel>
    </div>
  );
};

DomainHero.propTypes = {
  fileName: PropTypes.string.isRequired,
  caption: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default DomainHero;
