import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col} from 'react-bootstrap';
import Img from 'gatsby-image';

function CardApp({icon, tag, title, description, buttonText, staticCard, url, lg, sm}) {
  let cardProps = {as: 'a', href: url};
  let className = '';

  if (staticCard) {
    cardProps = {};
    className = 'static';
  }

  return (
    <Col lg={lg} sm={sm} className="app__column">
      <Card className={`app__card ${className}`} {...cardProps}>
        <Card.Img as={Img} fixed={icon} alt={title} />
        <Card.Subtitle as="h6" className="app__card__tag">
          {tag}
        </Card.Subtitle>
        <Card.Title as="h3" className="app__card__title">
          {title}
        </Card.Title>
        <Card.Text className="app__card__description">{description}</Card.Text>
        <Button disabled={staticCard}>{buttonText}</Button>
      </Card>
    </Col>
  );
}

CardApp.propTypes = {
  icon: PropTypes.object.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  lg: PropTypes.number,
  sm: PropTypes.number,
  staticCard: PropTypes.bool,
};

CardApp.defaultProps = {
  sm: 12,
  lg: 4,
};

export default CardApp;
