import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import CardApp from './card-app';

const CardAppPlatform = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_genomics-platform.png"}) {
        id
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return <CardApp icon={data.file.childImageSharp.fixed} {...props} />;
};

CardAppPlatform.propTypes = {
  icon: PropTypes.object.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  url: PropTypes.string,
  lg: PropTypes.number,
  sm: PropTypes.number,
};

CardAppPlatform.defaultProps = {
  tag: 'Sequencing Data and Analysis',
  title: 'Genomics Platform',
  description:
    'Access and analyze the world’s most comprehensive repositories of pediatric cancer-related genomics data and analysis tools.',
  buttonText: 'Access Data and Tools',
  url: 'https://platform.stjude.cloud',
};

export default CardAppPlatform;
