import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import CardApp from './card-app';

const CardAppVizComm = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_vizcomm.png"}) {
        id
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return <CardApp icon={data.file.childImageSharp.fixed} {...props} />;
};

CardAppVizComm.propTypes = {
  icon: PropTypes.object.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  url: PropTypes.string,
  lg: PropTypes.number,
  sm: PropTypes.number,
};

CardAppVizComm.defaultProps = {
  tag: 'Curated Visualizations',
  title: 'Visualization Community',
  description:
    "Create and share figures using St. Jude's unique genomic visualization tools such as ProteinPaint, GenomePaint, and Disco.",
  buttonText: 'See Visualizations',
  url: 'https://viz.stjude.cloud',
};

export default CardAppVizComm;
