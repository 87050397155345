import React from 'react';
import {Link, graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import {Col, Row, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

const StudyCard = ({title, url, external}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_studies-navy.png"}) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  let button;
  if (external) {
    button = (
      <Button variant="primary" href={url} className="studies__card__btn">
        {' '}
        Visit Site <FontAwesomeIcon icon={faExternalLinkAlt} className="icon__external-link" />
      </Button>
    );
  } else {
    button = (
      <Button variant="primary" as={Link} to={url} className="studies__card__btn">
        {' '}
        View Study{' '}
      </Button>
    );
  }
  return (
    <Col sm={12} lg={6} className="studies__card__column">
      <Card className="studies__card">
        <Row className="h-100" noGutters>
          <Col sm={4} className="studies__card__icon__container">
            <Img
              className="studies__card__icon"
              fixed={data.file.childImageSharp.fixed}
              alt={`${title} study icon`}
              fadeIn={false}
            />
          </Col>
          <Col sm={8} className="studies__card__body__container">
            <Card.Body className="studies__card__body">
              <Card.Title className="studies__card__title">{title}</Card.Title>
              {button}
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

StudyCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  external: PropTypes.bool.isRequired,
};

export default StudyCard;
