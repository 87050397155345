import React from 'react';
import PropTypes from 'prop-types';

const HeroCaption = ({children}) => <div className="domain-hero__caption">{children}</div>;

HeroCaption.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeroCaption;
